import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { ContactService } from '../contact.service';
import { Contact } from '../contact';
declare var $: any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  error:any;
  
  reasons = [{ reason: "Business Strategy" }, { reason: "Fashion, Apparel, & Textile Law" }, { reason: "Music" }, { reason: 'Liability & Risk' }, { reason: "TV, Film, & Theatre" }, { reason: "Digital Media & Tech" }, { reason: 'Other' }];

  constructor(private formBuilder: FormBuilder,private _contactService: ContactService) { }

  ngOnInit() {
      this.contactForm = this.formBuilder.group({
        firstname: ['', Validators.required],
        lastname: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        pone: ['', Validators.required],
        ptwo:['', Validators.required],
        pthree:['',Validators.required],
        message:['', Validators.required],
        bs:[''],
        fatl:[''],
        mus:[''],
        lr:[''],
        tft:[''],
        dmt:[''],
        other:['']
    });

    $(document).ready(function () {
      $('#p1').mask('000');
    });
    $(document).ready(function () {
      $('#p2').mask('000');
    });
    $(document).ready(function () {
      $('#p3').mask('000');
    });
  }


  get f() { return this.contactForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.contactForm.invalid) {
            return;
        }

        // display form values on success
        //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.contactForm.value, null, 4));
       // console.log(this.contactForm.value);
         //console.log(this.addForm.value);
        this._contactService.sendMail(this.contactForm.value).subscribe(data => {
          this.error="Message Sent Successfully"
          this.submitted = false;
          this.contactForm.reset();
      });
    }
}
