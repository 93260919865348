import { Injectable } from '@angular/core';
import { Contact } from './contact';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient) { }

  sendMail(contact: Contact){
    //return this.http.post<Contact[]>('http://localhost/toujours/toujoursdigitale/phpfiles/mail.php', contact);
    //return this.http.post<Contact[]>('http://toujoursdigitale.esta-official-online-us.com/phpfiles/mail.php', contact);
    //return this.http.post<Contact[]>('https://toujours-digitale.com/phpfiles/mail.php', contact);
    
    return this.http.post<Contact[]>('https://toujours-digitale.com/toujours-digitale/phpfiles/mail.php', contact);
  }
}
