import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ServiceComponent } from './service/service.component';
import { BlogComponent } from './blog/blog.component';


const routes: Routes = [
  { path:'' , component: HomeComponent},
  { path:'contact' , component: ContactComponent},
  { path:'about', component: AboutComponent},
  { path:'portfolio' , component: PortfolioComponent},
  { path:'service', component: ServiceComponent},
  { path:'blog', component: BlogComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
